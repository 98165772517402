<template>
  <div>
    <v-row class="" no-gutters>
      <v-col cols="12" class="py-0">
        <v-card-title class="headline px-0 pb-0">Leaderboard</v-card-title>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-btn-toggle v-if="!rankingKey" v-model="selectedType" class="pa-0" color="primary">
          <v-btn value="INDIVIDUAL"><v-icon title="Individual">fal fa-user</v-icon></v-btn>
          <v-btn value="EVENTS"><v-icon title="Events">fal fa-flag-checkered</v-icon></v-btn>
        </v-btn-toggle>
        <v-btn-toggle v-if="!rankingKey" v-model="selectedActivityType" class="ml-4 pa-0" color="primary">
          <v-btn value="RUNNING"><v-icon title="Running">{{$helpers.getActivityIcon('RUNNING')}}</v-icon></v-btn>
          <v-btn value="CYCLING"><v-icon title="Cycling">{{$helpers.getActivityIcon('CYCLING')}}</v-icon></v-btn>
          <v-btn value="WALKING"><v-icon title="Walking / Hiking">{{$helpers.getActivityIcon('WALKING')}}</v-icon></v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-alert v-if="rankingKey" type="info" class="mx-4">
      <v-row class="">
        <v-col class="grow py-0">
        You are looking at a previous ranking.
        </v-col>
        <v-col class="shrink py-0">
          <v-btn @click="resetRankingKey">Show current</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <!-- <v-skeleton-loader v-if="!ranking || $store.getters.loading" type="table-row"></v-skeleton-loader> -->
    <div v-if="selectedType == 'INDIVIDUAL' && ranking && ranking.length > 0" class="mt-6" >
      <h3 class="text-h5 ml-0">Individual Rankings</h3>
      <v-simple-table fixed-header >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"><v-chip outlined color="accent">#</v-chip></th>
              <th class="text-left">Name</th>
              <th class="text-left">Points</th>
              <th class="text-left">Distance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in ranking" :key="idx">
              <td><v-chip color="accent">{{ item.pos }}</v-chip></td>
              <td>{{ item.profile.name }} </td>
              <td class=""><ActivityScoreChip :points="item.pts"/></td>
              <td>{{ item.dist | distance }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-if="ranking && ranking.length == 0" class="text-italic pa-4" >
        No ranking available for this month, why not be first?
      </p>  
    </div>
    <div v-if="selectedType == 'EVENTS' && eventRanking && eventRanking.length > 0" class="mt-6">
      <h3 class="text-h5 ml-0">Event Rankings</h3>
      <v-simple-table fixed-header >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"><v-chip outlined color="accent">#</v-chip></th>
              <th class="text-left">Name</th>
              <th class="text-left">Points</th>
              <th class="text-left">Distance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in eventRanking" :key="idx">
              <td><v-chip color="accent">{{ item.pos }}</v-chip></td>
              <td><router-link :to="{name: 'event', params: {id: item.event.id}}">{{ item.event.name }}</router-link></td>
              <td class=""><ActivityScoreChip :points="item.pts"/></td>
              <td>{{ item.dist | distance }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import tileService from "@/services/tileService";
import ActivityScoreChip from "@/components/ActivityScoreChip";

export default {
  name: "TileRankingGrid",
  components: {
    ActivityScoreChip,
  },
  props: {
    tile: Object,
    type: String,
    activityType: String,
    rankingKey: String,
  },
  data() {
    return {
      ranking: null,
      eventRanking: null,
      selectedType: null,
      selectedActivityType: null,
      defaultType: 'INDIVIDUAL',
      defaultActivityType: 'RUNNING',
    };
  },
  async mounted() {
    this.selectedActivityType = (this.activityType || this.defaultActivityType).toUpperCase();
    this.selectedType = (this.type || this.defaultType).toUpperCase();
    await this.loadData();
  },
  methods: {
    async loadData() {
      if (this.tile) {
        this.ranking = (await tileService.getRanking(this.tile.id, this.selectedActivityType, this.rankingKey)).data.data;
        this.eventRanking = (await tileService.getEventRanking(this.tile.id, this.selectedActivityType, this.rankingKey)).data.data;
      }
    },
    async loadNextPage() {
    },
    async resetRankingKey() {
      this.rankingKey = null;
      await this.loadData();
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    async tile() {
      await this.loadData();
    },
    async selectedActivityType() {
      await this.loadData();
    },
  }
};
</script>

<style lang="scss">
  .full-width-separator {
    border-top: solid 1px rgba(0, 0, 0, 0.27);;
    .v-btn {
      margin-top: -24px !important;
      background-color: white;
    }
  }
</style>

