<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title v-if="tile" class="display-2">
        <v-col class="pa-0 pl-4 grow">{{ tile.name || 'Tile' }} </v-col>
        <v-col class="pa-0 pr-4 shrink">
          <span v-if="tile.country_code" :title="tile.country_code" :class="`ml-4 float--right v-sheet theme--light elevation-4 flag-icon flag-icon-${tile.country_code.toLowerCase() }`"></span>
        </v-col>
      </v-card-title>
      <TileMapIntro />
      <v-row>
        <v-col cols="12" md="6">
          <TileMap :focusTile="tile" :preventAutoLoad="true" />
        </v-col>
        <v-col cols="12" md="6">
          <TileRankingGrid :tile="tile" :activityType="$route.params.type" :rankingKey="$route.query.ranking" />
        </v-col>
      </v-row>
      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import tileService from "@/services/tileService";
import TileRankingGrid from "@/components/TileRankingGrid";
import TileMap from "@/components/TileMap";
import TileMapIntro from "@/components/TileMapIntro";

export default {
  name: "Tile",
  components: {
    TileRankingGrid,
    TileMap,
    TileMapIntro,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      tile: null,
    };
  },
  async mounted() {
    await this.loadData(this.$route.params.id);
  },
  methods: {
    async loadData(id) {
      this.tile = (await tileService.get(id)).data;
      var meta = {
        title: `Tile ${this.tile.name || ''}${this.tile.country_code == null ? '' : ' (' + this.tile.country_code + ')'} information, map and rankings on sodisp`,
        description: `View running, cycling and hiking rankings for the tile ${this.tile.name || ''}.`,
        //image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    breadcrumbItems() {
      return !this.tile ? [] : [
        { text: 'Tile map', exact: true, to: { name: 'map' } },
        this.tile.country_code == null ? null : { text: this.tile.country_code, exact: true, to: { name: 'map', params: {country: this.tile.country_code.toLowerCase() }} },
        { text: this.tile.name, disabled: true },
      ].filter(x =>x != null);
    },
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadData(to.params.id);
    next();
  },

};
</script>
<style lang="scss">
</style>

